import type { FeaturedEventRail } from '~/src/services/rails';
import { AhotuSection } from '~/src/templates/AhotuSection';

import FeaturedEvents from './FeaturedEvents';

interface FeaturedEventRailsProps {
  featuredEventRails: FeaturedEventRail[];
}

const FeaturedRails = ({ featuredEventRails }: FeaturedEventRailsProps) => {
  return (
    <>
      {featuredEventRails.map(({ id, events }) => (
        <AhotuSection key={id} layout="left-container" id={id as string}>
          <FeaturedEvents
            events={events}
            useOn="homepage"
            fromSection={`${id}_section`}
            titleKey={`common:${id}rail`}
          />
        </AhotuSection>
      ))}
    </>
  );
};

export default FeaturedRails;
